import Router from 'vue-router';
import Vue from 'vue';
import store from './store';
Vue.use(Router);
const vueRouter = new Router({
	base: process.env.VUE_APP_PATH,
	mode: process.env.VUE_APP_MODE,
	routes: [
		{
			path: '/',
			component: () => import(/* webpackChunkName: "page" */ '@/page/index'),
			children: [
				{
					path: '',
					component: () => import(/* webpackChunkName: "page" */ '@/page/home'),
				},
				{
					path: 'list',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list'),
				},
				{
					path: 'document',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/doc'),
				},
				{
					path: 'category',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/category'),
				},
				{
					path: 'db',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/db'),
				},
				{
					path: 'map',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/map'),
				},
				// {
				// 	path: 'document',
				// 	component: () => import(/* webpackChunkName: "page" */ '@/page/list/doc'),
				// },
				{
					path: 'components',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/components'),
				},
				{
					path: 'record',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/record'),
				},
				{
					path: 'fee',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/fee'),
				},
				{
					path: 'person',
					component: () => import(/* webpackChunkName: "page" */ '@/page/list/person'),
				},
				{
					path: '/login',
					name: 'login',
					component: () => import(/* webpackChunkName: "page" */ '@/page/login'),
				},
				{
					path: '/register',
					name: 'register',
					component: () => import(/* webpackChunkName: "page" */ '@/page/register'),
				},
				{
					path: '/pwReset',
					name: 'pwReset',
					component: () => import(/* webpackChunkName: "page" */ '@/page/pwReset'),
				},
			],
		},
		{
			path: '/create',
			name: 'create',
			component: () => import(/* webpackChunkName: "page" */ '@/page/create'),
		},
		{
			path: '/build/:id',
			name: 'build',
			component: () => import(/* webpackChunkName: "page" */ '@/page/build'),
		},
		{
			path: '/view/:id',
			name: 'view',
			component: () => import(/* webpackChunkName: "page" */ '@/page/view'),
		},
	],
});
const needLoginPaths = ['/db', '/fee', '/person'];
vueRouter.beforeEach((to, from, next) => {
	const hasToken = JSON.parse(localStorage.getItem('loginstore') || '{}').loginToken;
	if (!hasToken && needLoginPaths.includes(to.path)) {
		// next({ path: '/login' });
		store.state.isLogin = true;
		next();
		return;
	}
	next();
});
export default vueRouter;
