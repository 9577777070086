// import { createStore } from "vuex"

import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
// console.log(createStore)
const store = new Vuex.Store({
	state() {
		return {
			userData: {
				realmName: '',
				phone: '',
			},
			isLogin: false,
		};
	},
	mutations: {
		LoginOut(state) {
			state.userData = {
				realmName: '',
				phone: '',
			};
			console.log('LoginOut');
			localStorage.removeItem('loginstore'); //setItem('loginstore','');
			window.location.reload();
		},
		showLogin(state, is) {
			state.isLogin = Boolean(is);
		},
		increment(state) {
			state.count++;
		},
		setUserData(state, data) {
			const overTime = data.ExpiredDate * 60000;
			const overDate = Date.now() + overTime;
			state.userData = { ...data, overDate };
			localStorage.setItem('loginstore', JSON.stringify(state.userData));
		},
	},
});
console.log(store, 'ssss');
export default store;
