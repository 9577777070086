import Vue from "vue"
import ElementUI from "element-ui"
import axios from "./axios"
import "element-ui/lib/theme-chalk/index.css"
import dataV from "@jiaminghi/data-view"
import router from "./router.js"
import { website } from "@/config.js"
import draggable from "@/page/components/draggable"
import { loadScript } from "@/utils/utils"
import App from "./App.vue"
import "./styles/common.scss"
import "@/utils/es6"
import store from "./store.js"
// import Vuex from "vuex"
// import '@/mock/'
//导入主题文件
import "@/theme/index.js"
Vue.config.productionTip = false
window.axios = axios
window.$loadScript = loadScript
document.title = website.title
// Vue.use(store)
console.log(store, "ssss")
Vue.use(ElementUI)
Vue.use(window.AVUE)
Vue.component("avue-draggable", draggable)
Vue.prototype.$website = website
Vue.use(dataV)
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app")
